import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AuthContext from './AuthContext';
import { GetData, PostData, PutData, PutFormData } from '../../services';
import { useLocalStorage, useSessionStorage } from './useLocalStorage';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const AuthState = (props) => {
    const [user, setUser] = useLocalStorage('HHStorage', null);
    // const [user, setUser] = useSessionStorage('HHStorage', null);

    const [userDetails, setUserDetails] = useState([]);
    // const tokenTime = user ? user.token.accessTokenExpiresIn : '';
    // const [tokenExpirytime, setTokenExpirytime] = useState(tokenTime);

    const navigate = useNavigate();
    const addUser = async (data) => {
        const res = await PostData(false, '/users', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateUser = async (data, id) => {
        const res = await PutFormData(true, '/users/' + id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updatePassword = async (data) => {
        const res = await PostData(true, '/users/change-password/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const confirmUser = async ({ id, status }) => {
        const res = await PutData(false, '/users/updateStatus/' + id, { status });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const loginUser = async (data) => {
        const res = await PostData(false, '/Admin_Login', data);
        if (res.status === 200) {
            // setUser(res.payload);
            return res.payload;
        }
    };

    const loginOTP = async (data) => {
        setUser(data);
    };

    const logout = async () => {
        localStorage.removeItem('HHStorage');
        // sessionStorage.removeItem('HHStorage');

        navigate('/', { replace: true });
        setUser(null);
    };

    const getUserDetails = async ({ id }) => {
        const res = await GetData(true, '/users/' + id);
        setUserDetails(res.payload);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const refreshToken = async (data) => {
        const res = await PostData(true, '/refresh-token', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const changePassword = async (data) => {
        const res = await PostData(true, '/changePassword', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                userDetails,
                user,
                addUser,
                confirmUser,
                loginUser,
                logout,
                refreshToken,
                updateUser,
                getUserDetails,
                updatePassword,
                changePassword,
                loginOTP
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

AuthState.propTypes = {
    children: PropTypes.node
};

export default AuthState;
