import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SnackContext from './SnackContext';
import Typography from '@mui/material/Typography';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RenderSnack({ message, secondMessage, open, options, handleClose }) {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={options} sx={{ width: '100%' }}>
                {secondMessage ? (
                    <>
                        <Typography color="Black" variant="h3" gutterBottom>
                            {message}
                        </Typography>
                        <Typography color="Black" fontSize="15px" variant="subtitle2" gutterBottom>
                            {secondMessage}
                        </Typography>
                    </>
                ) : (
                    message
                )}
            </Alert>
        </Snackbar>
    );
}

let uniqueId = 2;

export const SnackState = ({ children }) => {
    const [{ current, queue }, setState] = useState({ current: null, queue: [] });

    function createSnack(message, secondMessage, options) {
        const id = uniqueId++;
        const snack = { id, message, secondMessage, open: true, options };

        if (current) {
            setState({ queue, current: snack });
            // setState({ current, queue: queue.concat(snack) });
        } else {
            setState({ queue, current: snack });
        }
        return id;
    }

    function handleClose() {
        setState((currentState) => ({
            ...currentState,
            current: { ...currentState.current, open: false }
        }));
        // time to snack close animation
        openNext();
    }

    function openNext() {
        if (queue.length) {
            setState({ current: queue[0], queue: queue.slice(1) });
        } else {
            setState({ current: null, queue: [] });
        }
    }

    return (
        <SnackContext.Provider value={{ createSnack }}>
            {current && <RenderSnack key={current.id} {...current} handleClose={handleClose} />}
            {children}
        </SnackContext.Provider>
    );
};

SnackState.propTypes = {
    children: PropTypes.node,
    open: PropTypes.node
};

RenderSnack.propTypes = {
    message: PropTypes.string,
    secondMessage: PropTypes.node,
    options: PropTypes.node,
    handleClose: PropTypes.func,
    children: PropTypes.node
};

export default SnackState;
