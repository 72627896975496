// import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AllPathContext from './AllPathContext';
import { PostData, PostFormData } from '../../services';

const AllPathState = (props) => {
    const addFakeViewLike = async (data) => {
        const res = await PostData(true, '/addFakeViewLike', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllDaysByMonth = async (data) => {
        const res = await PostData(true, '/getAllDaysByMonth', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllMonthsByYear = async (data) => {
        const res = await PostData(true, '/getAllMonthsByYear', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllVerifiedDaysByMonth = async (data) => {
        const res = await PostData(true, '/getAllVerifiedDaysByMonth', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllVerifiedMonthsByYear = async (data) => {
        const res = await PostData(true, '/getAllVerifiedMonthsByYear', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllAgencyAndClipUploader = async (data) => {
        const res = await PostData(true, '/getAllAgencyAndClipUploader', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllShotsAndClipByYear = async (data) => {
        const res = await PostData(true, '/getAllShotsAndClipByYear', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addVideointoDiscovery = async (data) => {
        const res = await PostData(true, '/addVideointoDiscovery', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const all_discovery_sections = async (data) => {
        const res = await PostData(true, '/all_discovery_sections', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addForYou = async (data) => {
        const res = await PostData(true, '/addForYou', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addForYouMark = async (data) => {
        const res = await PostData(true, '/addForYouMark', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addVideoAsMark = async (data) => {
        const res = await PostData(true, '/addVideoAsMark', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const DeleteVideo = async (data) => {
        const res = await PostData(true, '/AdminDeleteVideo', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const changeDiscriptionOfVideo = async (data) => {
        const res = await PostData(true, '/changeDiscriptionOfVideo', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const varifiedbankByAdmin = async (data) => {
        const res = await PostData(true, '/varifiedbankByAdmin', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getKycDashboard = async (data) => {
        const res = await PostData(true, '/getKycDashboard', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getDiamondUserDashboard = async (data) => {
        const res = await PostData(true, '/getDiamondUserDashboard', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllCreatorDaysByMonth = async (data) => {
        const res = await PostData(true, '/getAllCreatorDaysByMonth', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllCreatorMonthsByYear = async (data) => {
        const res = await PostData(true, '/getAllCreatorMonthsByYear', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getWithdrawlUserDashboard = async (data) => {
        const res = await PostData(true, '/getWithdrawlUserDashboard', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllReferralDaysByMonth = async (data) => {
        const res = await PostData(true, '/getAllReferralDaysByMonth', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllReffralMonthsByYear = async (data) => {
        const res = await PostData(true, '/getAllReffralMonthsByYear', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAgency = async (data) => {
        const res = await PostData(true, '/getAgency', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const checkUserName = async (data) => {
        const res = await PostData(true, '/checkUserName', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const sendCommentFromPanel = async (data) => {
        const res = await PostData(true, '/sendCommentFromPanel', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllUsersInfo_ = async (data) => {
        const res = await PostData(true, '/getAllUsersInfo_', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllUsersVideoAudio = async (data) => {
        const res = await PostData(true, '/getAllUsersVideoAudio', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const sendNotificationFromPanel = async (data) => {
        const res = await PostFormData(true, '/sendNotificationFromPanel', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllAgencyInfo_ = async (data) => {
        const res = await PostData(true, '/getAllAgencyInfo_', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const sendNotificationFromPanelToAgencyUser = async (data) => {
        const res = await PostFormData(true, '/sendNotificationFromPanelToAgencyUser', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getadminAwardWinner = async (data) => {
        const res = await PostFormData(true, '/getadminAwardWinner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addadminAwardWinner = async (data) => {
        const res = await PostFormData(true, '/addadminAwardWinner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const deleteadminAwardWinner = async (data) => {
        const res = await PostFormData(true, '/deleteadminAwardWinner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateadminAwardWinner_1 = async (data) => {
        const res = await PostFormData(true, '/updateadminAwardWinner_1', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addadminTimeEvent = async (data) => {
        const res = await PostFormData(true, '/addadminTimeEvent', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getadminTimeEvent = async (data) => {
        const res = await PostFormData(true, '/getadminTimeEvent', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const deleteadminTimeEvent = async (data) => {
        const res = await PostFormData(true, '/deleteadminTimeEvent', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allTimeEvent = async (data) => {
        const res = await PostFormData(true, '/allTimeEvent', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <AllPathContext.Provider
            value={{
                addFakeViewLike,
                getAllDaysByMonth,
                getAllMonthsByYear,
                getAllVerifiedDaysByMonth,
                getAllVerifiedMonthsByYear,
                getAllAgencyAndClipUploader,
                getAllShotsAndClipByYear,
                addVideointoDiscovery,
                all_discovery_sections,
                addForYou,
                addForYouMark,
                addVideoAsMark,
                DeleteVideo,
                changeDiscriptionOfVideo,
                varifiedbankByAdmin,
                getKycDashboard,
                getDiamondUserDashboard,
                getAllCreatorDaysByMonth,
                getAllCreatorMonthsByYear,
                getWithdrawlUserDashboard,
                getAllReferralDaysByMonth,
                getAllReffralMonthsByYear,
                getAgency,
                checkUserName,
                sendCommentFromPanel,
                getAllUsersInfo_,
                getAllUsersVideoAudio,
                sendNotificationFromPanel,
                getAllAgencyInfo_,
                sendNotificationFromPanelToAgencyUser,
                getadminAwardWinner,
                addadminAwardWinner,
                deleteadminAwardWinner,
                updateadminAwardWinner_1,
                addadminTimeEvent,
                getadminTimeEvent,
                deleteadminTimeEvent,
                allTimeEvent
            }}
        >
            {props.children}
        </AllPathContext.Provider>
    );
};

AllPathState.propTypes = {
    children: PropTypes.node
};

export default AllPathState;
