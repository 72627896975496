import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import StockChart from './DailyReportCharts';
import StockChart from './DailyReportCharts';

const InstanceCharts = ({ title, name, path }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        axios
            .get(path)
            .then((response) => {
                console.log(response.data);
                setData(response.data);
                setLoading(false); // Data is loaded, set loading to false
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false); // Even if there's an error, stop loading
            });
    }, [path]);

    if (loading) {
        return <div>Loading...</div>; // Render loading state
    }

    return (
        <div className="App">
            <StockChart title={title} name={name} dates={data} />
        </div>
    );
};

export default InstanceCharts;
