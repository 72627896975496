import axios from 'axios';

async function Refresh() {
    const { token } = JSON.parse(localStorage.getItem('HHStorage'));
    // const { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

    let apiUrl = '/refresh-token';
    let axiosConfig;
    axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_WEB_API_KEY,
            platform: 'web',
            Authorization: `Bearer ${token.access_token}`
        }
    };

    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, { refresh_token: token.refresh_token }, axiosConfig);
            if (res.status === 200) {
                localStorage.setItem(
                    // sessionStorage.setItem(
                    'HHStorage',
                    JSON.stringify({
                        token: res.data.token
                    })
                );
            }
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
    });
}

export async function PostData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(localStorage.getItem('HHStorage'));
        // let { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    });
}

export async function PutData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(HHStorageage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.put(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    });
}

export async function GetData(isAuthorized, apiUrl) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(localStorage.getItem('HHStorage'));
        // let { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }

        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.get(process.env.REACT_APP_BASE_URL + apiUrl, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    });
}

export async function addCandidate(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(localStorage.getItem('HHStorage'));
        // let { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
    });
}

export async function DeleteData(isAuthorized, apiUrl) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(localStorage.getItem('HHStorage'));
        // let { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.delete(process.env.REACT_APP_BASE_URL + apiUrl, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    });
}

export async function PostFormData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(localStorage.getItem('HHStorage'));
        // let { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }
        axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    });
}

export async function PutFormData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        let { token } = JSON.parse(localStorage.getItem('HHStorage'));
        // let { token } = JSON.parse(sessionStorage.getItem('HHStorage'));

        const timeNow = Math.floor(Date.now() / 1000);

        if (timeNow >= token.accessTokenExpiresIn) {
            const { payload } = await Refresh();
            token = payload.token;
        }
        axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web',
                Authorization: `Bearer ${token.access_token}`
            }
        };
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                platform: 'web'
            }
        };
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.put(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    });
}
