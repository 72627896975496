// import * as React from 'react';

// // material-ui
// import { useTheme } from '@mui/material/styles';
// import { Avatar, Box, ButtonBase } from '@mui/material';

// // project imports
// // import CartList from './CartList';

// // assets
// import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

// ==============================|| NOTIFICATION ||============================== //

const CartSection = () => {
    // const theme = useTheme();

    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <>
            {/* <Box
                display={['block', 'block', 'block', 'block', 'none']}
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleClickOpen}
                    >
                        <ShoppingBasketIcon stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <CartList open={open} handleClose={handleClose} /> */}
        </>
    );
};

export default CartSection;
