import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Users routing
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const Users = Loadable(lazy(() => import('views/pages/users')));
// import Users from "../views/pages/users";
const AllVerifiedUser = Loadable(lazy(() => import('views/pages/users/all-verified-users')));
const AllAgencyUsers = Loadable(lazy(() => import('views/pages/users/all-agency-users')));
const AllSubscriber = Loadable(lazy(() => import('views/pages/users/all-subscriber')));
const AllFailedSubscriber = Loadable(lazy(() => import('views/pages/users/all-failed-subscriber')));
const ClipUploaderUser = Loadable(lazy(() => import('views/pages/users/clip-uploader-user')));
const ProfileVerification = Loadable(lazy(() => import('views/pages/users/profile-verification')));
const UserDetails = Loadable(lazy(() => import('views/pages/users/user-details')));
const UserTimeEventData = Loadable(lazy(() => import('views/pages/users/user-time-event-data')));
const UserDiamondDetails = Loadable(lazy(() => import('views/pages/users/userDiamondDetails')));
const MarkedUser = Loadable(lazy(() => import('views/pages/users/markedUser')));
const MarkedAcceptDiamondsUser = Loadable(lazy(() => import('views/pages/users/markedAcceptDiamondsUser')));
const Profile = Loadable(lazy(() => import('views/pages/profile')));
const UsersKyc = Loadable(lazy(() => import('views/pages/users/UsersKyc')));
const Creators = Loadable(lazy(() => import('views/pages/users/creators')));
const WithdrawRequest = Loadable(lazy(() => import('views/pages/users/withdrawRequest')));
const UserWallet = Loadable(lazy(() => import('views/pages/users/userWallet')));
const UserWalletHistory = Loadable(lazy(() => import('views/pages/users/userWalletHistory')));
const UserDiamond = Loadable(lazy(() => import('views/pages/users/userDiamond')));
const ManageAgency = Loadable(lazy(() => import('views/pages/users/manageAgency')));
const ReferralUser = Loadable(lazy(() => import('views/pages/users/referralUser')));
const DiscoverySection = Loadable(lazy(() => import('views/pages/users/discoverySection')));
const UserFeedback = Loadable(lazy(() => import('views/pages/users/userFeedback')));
const Contest = Loadable(lazy(() => import('views/pages/users/contest')));
const Banner = Loadable(lazy(() => import('views/pages/users/banner')));
const ReportedUsers = Loadable(lazy(() => import('views/pages/users/reportedUsers')));
const AddFollowers = Loadable(lazy(() => import('views/pages/users/add-followers')));
const AddUpiSubscriber = Loadable(lazy(() => import('views/pages/users/add-upi-subscriber')));
const AddComment = Loadable(lazy(() => import('views/pages/users/add-comment')));
const SendNotification = Loadable(lazy(() => import('views/pages/users/send-notification')));
const SendAgencyNotification = Loadable(lazy(() => import('views/pages/users/send-agency-notification')));
const AwardContnet = Loadable(lazy(() => import('views/pages/users/award-contnet')));
const TimeEvent = Loadable(lazy(() => import('views/pages/users/time-event')));

// Videos routing
const AllClips = Loadable(lazy(() => import('views/pages/videos/allClips')));
const AllShots = Loadable(lazy(() => import('views/pages/videos/allShots')));
const AllImages = Loadable(lazy(() => import('views/pages/videos/allImages')));
const TrendingShots = Loadable(lazy(() => import('views/pages/videos/trendingShots')));
const TrendingClip = Loadable(lazy(() => import('views/pages/videos/trendingClip')));
const TrendingMarkShots = Loadable(lazy(() => import('views/pages/videos/trendingMarkShots')));
const MarkedShots = Loadable(lazy(() => import('views/pages/videos/markedShots')));
const ExploreShort = Loadable(lazy(() => import('views/pages/videos/exploreShort')));
const AllSounds = Loadable(lazy(() => import('views/pages/sounds')));
const ReportedItems = Loadable(lazy(() => import('views/pages/videos/reportedItems')));
const ExploreHashtag = Loadable(lazy(() => import('views/pages/videos/exploreHashtag')));
const Formshot = Loadable(lazy(() => import('views/pages/form/shots/Form')));
const FormImages = Loadable(lazy(() => import('views/pages/form/images/Form')));
const FormClip = Loadable(lazy(() => import('views/pages/form/clip/Form')));
const BotUploaded = Loadable(lazy(() => import('views/botuploaded/Botuploaded')));
const EditUsers = Loadable(lazy(() => import('views/pages/users/bot-users')));
const AdminUsers = Loadable(lazy(() => import('views/pages/users/Admin_users/Admin_users')));

import UsersState from '../context/users/UsersState';
import VideoState from '../context/videos/VideoState';
import SoundState from '../context/sounds/SoundState';
import { element } from 'prop-types';
import Allreports from 'views/pages/daily_reports/Allreports';
const Role = Loadable(lazy(() => import('views/pages/Access_Permission/Role&Permission')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: 'all-users',
            element: (
                <UsersState>
                    <Users />
                </UsersState>
            )
        },
        {
            path: 'all-verified-users',
            element: (
                <UsersState>
                    <AllVerifiedUser />
                </UsersState>
            )
        },
        {
            path: 'all-agency-users',
            element: (
                <UsersState>
                    <AllAgencyUsers />
                </UsersState>
            )
        },
        {
            path: 'all-subscribers',
            element: (
                <UsersState>
                    <AllSubscriber />
                </UsersState>
            )
        },
        {
            path: 'add-followers',
            element: (
                <UsersState>
                    <AddFollowers />
                </UsersState>
            )
        },
        {
            path: 'add-upi-subscriber',
            element: (
                <UsersState>
                    <AddUpiSubscriber />
                </UsersState>
            )
        },
        {
            path: 'add-comment',
            element: (
                <UsersState>
                    <AddComment />
                </UsersState>
            )
        },
        {
            path: 'add-notification',
            element: (
                <UsersState>
                    <SendNotification />
                </UsersState>
            )
        },
        {
            path: 'send-agency-notification',
            element: (
                <UsersState>
                    <SendAgencyNotification />
                </UsersState>
            )
        },
        {
            path: 'award-content',
            element: (
                <UsersState>
                    <AwardContnet />
                </UsersState>
            )
        },
        {
            path: 'time-event',
            element: (
                <UsersState>
                    <TimeEvent />
                </UsersState>
            )
        },
        {
            path: 'all-failed-subscribers',
            element: (
                <UsersState>
                    <AllFailedSubscriber />
                </UsersState>
            )
        },
        {
            path: 'clip-uploader-user',
            element: (
                <UsersState>
                    <ClipUploaderUser />
                </UsersState>
            )
        },
        {
            path: 'profile-verification',
            element: (
                <UsersState>
                    <ProfileVerification />
                </UsersState>
            )
        },
        {
            path: 'all-sounds',
            element: (
                <SoundState>
                    <AllSounds />
                </SoundState>
            )
        },
        {
            path: 'all-clip',
            element: (
                <VideoState>
                    <AllClips />
                </VideoState>
            )
        },
        {
            path: 'all-shots',
            element: (
                <VideoState>
                    <AllShots />
                </VideoState>
            )
        },
        {
            path: 'trending-shots',
            element: (
                <VideoState>
                    <TrendingShots />
                </VideoState>
            )
        },
        {
            path: 'trending-clip',
            element: (
                <VideoState>
                    <TrendingClip />
                </VideoState>
            )
        },
        {
            path: 'trending-mark-shots',
            element: (
                <VideoState>
                    <TrendingMarkShots />
                </VideoState>
            )
        },

        {
            path: 'marked-shots',
            element: (
                <VideoState>
                    <MarkedShots />
                </VideoState>
            )
        },

        {
            path: 'all-images',
            element: (
                <VideoState>
                    <AllImages />
                </VideoState>
            )
        },
        {
            path: 'marked-user',
            element: (
                <UsersState>
                    <MarkedUser />
                </UsersState>
            )
        },
        {
            path: 'marked-accept-diamonds-user',
            element: (
                <UsersState>
                    <MarkedAcceptDiamondsUser />
                </UsersState>
            )
        },

        {
            path: 'explore-short',
            element: (
                <VideoState>
                    <ExploreShort />
                </VideoState>
            )
        },

        {
            path: 'users-kyc',
            element: (
                <UsersState>
                    <UsersKyc />
                </UsersState>
            )
        },
        {
            path: 'Creator',
            element: (
                <UsersState>
                    <Creators />
                </UsersState>
            )
        },
        {
            path: 'withdraw-request',
            element: (
                <UsersState>
                    <WithdrawRequest />
                </UsersState>
            )
        },
        {
            path: 'user-wallet',
            element: (
                <UsersState>
                    <UserWallet />
                </UsersState>
            )
        },
        {
            path: 'user-wallet-history/:id',
            element: (
                <UsersState>
                    <UserWalletHistory />
                </UsersState>
            )
        },
        {
            path: 'user-diamond',
            element: (
                <UsersState>
                    <UserDiamond />
                </UsersState>
            )
        },
        {
            path: 'manage-agency',
            element: (
                <UsersState>
                    <ManageAgency />
                </UsersState>
            )
        },
        {
            path: 'referral-user',
            element: (
                <UsersState>
                    <ReferralUser />
                </UsersState>
            )
        },
        {
            path: 'discovery-section',
            element: (
                <UsersState>
                    <DiscoverySection />
                </UsersState>
            )
        },
        {
            path: 'user-feedback',
            element: (
                <UsersState>
                    <UserFeedback />
                </UsersState>
            )
        },
        {
            path: 'contest',
            element: (
                <UsersState>
                    <Contest />
                </UsersState>
            )
        },
        {
            path: 'banner',
            element: (
                <UsersState>
                    <Banner />
                </UsersState>
            )
        },
        {
            path: 'details/:id',
            element: (
                <UsersState>
                    <UserDetails />
                </UsersState>
            )
        },
        {
            path: 'user-time-event-data/:id',
            element: (
                <UsersState>
                    <UserTimeEventData />
                </UsersState>
            )
        },
        {
            path: 'diamond/:id',
            element: (
                <UsersState>
                    <UserDiamondDetails />
                </UsersState>
            )
        },
        {
            path: 'account',
            element: (
                <UsersState>
                    <Profile />
                </UsersState>
            )
        },
        {
            path: 'reported-items',
            element: (
                <VideoState>
                    <ReportedItems />
                </VideoState>
            )
        },
        {
            path: 'explore-hashtag',
            element: (
                <VideoState>
                    <ExploreHashtag />
                </VideoState>
            )
        },
        {
            path: 'reported-users',
            element: (
                <UsersState>
                    <ReportedUsers />
                </UsersState>
            )
        },
        {
            path: 'form',
            element: (
                // <UsersState>
                <Formshot />
                // </UsersState>
            )
        },
        {
            path: 'form-images',
            element: <FormImages />
        },
        {
            path: 'form-clip',
            element: <FormClip />
        },
        {
            path: 'daily-reports',
            element: <Allreports />
        },
        {
            path: 'upload-bot',
            element: <BotUploaded />
        },
        {
            path: 'bot-users',
            element: (
                <UsersState>
                    <EditUsers />
                </UsersState>
            )
        },
        {
            path: 'Access',
            element: (
                // <UsersState>
                <Role />
                // </UsersState>
            )
        },
        {
            path: 'admin_users',
            element: <AdminUsers />
        }
    ]
};

export default MainRoutes;
