import { useState } from 'react';
// import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VideoContext from './VideoContext';
import { PostData } from '../../services';

const VideoState = (props) => {
    const getVideoClips = async (data) => {
        const res = await PostData(true, '/admin_show_allClip', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideos = async (data) => {
        const res = await PostData(true, '/admin_show_allVideos_new', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allImages = async (data) => {
        const res = await PostData(true, '/admin_show_allImages', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideosForYou = async (data) => {
        const res = await PostData(true, '/admin_show_allVideosForYou', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideosForYouClip = async (data) => {
        const res = await PostData(true, '/admin_show_allVideosForYouClip', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideosForYouMark = async (data) => {
        const res = await PostData(true, '/admin_show_allVideosForYouMark', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideosMark = async (data) => {
        const res = await PostData(true, '/admin_show_allVideosMark', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideosExplore = async (data) => {
        const res = await PostData(true, '/admin_show_allVideosExplore', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const All_Reported = async (data) => {
        const res = await PostData(true, '/All_Reported', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addFakeViewLike = async (data) => {
        const res = await PostData(true, '/addFakeViewLike', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allowDownload = async (data) => {
        const res = await PostData(true, '/allowDownload', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addVidAmount = async (data) => {
        const res = await PostData(true, '/addVidAmount', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const adminUpdateUser = async (data) => {
        const res = await PostData(true, '/adminUpdateUser', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const setOrderVideosExplore = async (data) => {
        const res = await PostData(true, '/setOrderVideosExplore', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getPanelHashtagList = async (data) => {
        const res = await PostData(true, '/getPanelHashtagList', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAddedPanelHashtagList = async (data) => {
        const res = await PostData(true, '/getAddedPanelHashtagList', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const setOrderVideosExploreHashtag = async (data) => {
        const res = await PostData(true, '/setOrderVideosExploreHashtag', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removeAddedPanelHashtagList = async (data) => {
        const res = await PostData(true, '/removeAddedPanelHashtagList', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addhashtag2explore = async (data) => {
        const res = await PostData(true, '/addhashtag2explore', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removefromforyou = async (data) => {
        const res = await PostData(true, '/removefromforyou', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removefromforyouMark = async (data) => {
        const res = await PostData(true, '/removefromforyouMark', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removevideofrommarked = async (data) => {
        const res = await PostData(true, '/removevideofrommarked', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removeVideoFromExplore = async (data) => {
        const res = await PostData(true, '/removeVideoFromExplore', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addVideoToExplore = async (data) => {
        const res = await PostData(true, '/addVideoToExplore', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const reportStatus = async (data) => {
        const res = await PostData(true, '/reportAction', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const uploadVideoNew = async (data) => {
        const res = await PostData(true, '/uploadVideoNew', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    return (
        <VideoContext.Provider
            value={{
                getVideoClips,
                admin_show_allVideos,
                admin_show_allImages,
                admin_show_allVideosForYou,
                admin_show_allVideosForYouClip,
                admin_show_allVideosForYouMark,
                admin_show_allVideosMark,
                admin_show_allVideosExplore,
                All_Reported,
                addFakeViewLike,
                allowDownload,
                addVidAmount,
                adminUpdateUser,
                setOrderVideosExplore,
                getPanelHashtagList,
                getAddedPanelHashtagList,
                setOrderVideosExploreHashtag,
                removeAddedPanelHashtagList,
                addhashtag2explore,
                removefromforyou,
                removefromforyouMark,
                removevideofrommarked,
                removeVideoFromExplore,
                addVideoToExplore,
                reportStatus,
                uploadVideoNew
            }}
        >
            {props.children}
        </VideoContext.Provider>
    );
};

VideoState.propTypes = {
    children: PropTypes.node
};

export default VideoState;
