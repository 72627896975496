// import React from 'react';
// // import sourec from './Scrapping';
// import Shots_count from './Shots_count';
// import Clips_count from './Clips_count';
// import Images from './Images';
// import Scrapping from './Scrapping';
// import UsersCount from './UsersCount';

// const Allreports = () => {
//     return (
//         <div>
//             <Scrapping />
//             <br />
//             <Shots_count />
//             <br />
//             <Clips_count />
//             <br />
//             <Images />
//             <br />
//             <UsersCount />
//         </div>
//     );
// };

// export default Allreports;

import { useState } from 'react';
// import InstanceCharts from './InstanceCharts';
import InstanceCharts from './InstanceCharts';
// import '../../../index.css';

const Allreports = () => {
    const [value, setValue] = useState('');
    const [value2, setValue2] = useState('');
    const [submit, setSubmit] = useState(false);
    const [submit2, setSubmit2] = useState(false);
    const handleOnChange = (event) => {
        setValue(event.target.value);
    };
    const handleOnChange2 = (event) => {
        setValue2(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmit(true);
    };
    const handleSubmit2 = (event) => {
        event.preventDefault();
        setSubmit2(true);
    };

    return (
        <div className="flex flex-col">
            <div className="flex w-full p-5">
                <div className="w-1/2">
                    <InstanceCharts
                        title={'Shots Uploaded Count Chart'}
                        name={'Shots Count'}
                        path={`${process.env.REACT_APP_BASE_URL}/getShotsCount`}
                    />
                </div>
                <div className="w-1/2">
                    <InstanceCharts
                        title={'Signup User Count Chart'}
                        name={'SignUp Count'}
                        path={`${process.env.REACT_APP_BASE_URL}/getUserJoinedCount`}
                    />
                </div>
            </div>
            <div className="flex w-full p-5">
                <div className="w-1/2">
                    <InstanceCharts
                        title={'Images Uploaded Count Chart'}
                        name={'Images Count'}
                        path={`${process.env.REACT_APP_BASE_URL}/getImagesCount`}
                    />
                </div>
                <div className="w-1/2">
                    <InstanceCharts
                        title={'Clip Uploaded Count Chart'}
                        name={'Clip Count'}
                        path={`${process.env.REACT_APP_BASE_URL}/ClipCount`}
                    />
                </div>
            </div>
            <div className="flex w-full p-5">
                <div className="w-1/2">
                    <InstanceCharts
                        title={'Shots Scrapped Count Chart'}
                        name={'Scrapped Shots Count'}
                        path={`${process.env.REACT_APP_BASE_URL}/getScrappingCount`}
                    />
                </div>
                <div className="w-1/2">
                    <InstanceCharts
                        title={'Likes Count Chart'}
                        name={'Likes Count'}
                        path={`${process.env.REACT_APP_BASE_URL}/getTotalLikesCount`}
                    />
                </div>
            </div>
            <div className="flex w-full p-5">
                <form className="flex flex-col gap-2 justify-center items-center w-1/2" onSubmit={handleSubmit}>
                    <p className="text-lg font-semibold">Get Likes Chart for a Particular Video</p>
                    <label className="p-4" htmlFor="User_fb_id">
                        Enter Video_id
                    </label>
                    <br />
                    <input
                        className="p-4 bg-white flex items-center justify-center"
                        type="text"
                        name="User_fb_id"
                        placeholder="Enter video_id"
                        onChange={handleOnChange}
                        value={value}
                    />
                    <br />
                    <button type="submit" className="p-5 bg-orange-500 text-white">
                        Generate
                    </button>
                </form>
                <div className="w-1/2">
                    {submit && value ? (
                        <div className="w-full">
                            <div>
                                <InstanceCharts
                                    title={'Likes Count Chart Video'}
                                    name={'Video Likes Count'}
                                    path={`${process.env.REACT_APP_BASE_URL}/getTotalLikesCountUsingVideo_id/${value}`}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="text-lg font-semibold">Enter Video_id</p>
                    )}
                </div>
            </div>
            <div className="flex w-full p-5">
                <form className="flex flex-col gap-2 justify-center items-center w-1/2" onSubmit={handleSubmit2}>
                    <p className="text-lg font-semibold">Get Comments Chart for a Particular Video</p>
                    <label className="p-4" htmlFor="User_fb_id">
                        Enter Video_id
                    </label>
                    <br />
                    <input
                        className="p-4 bg-white flex items-center justify-center"
                        type="text"
                        name="User_fb_id"
                        placeholder="Enter video_id"
                        onChange={handleOnChange2}
                        value={value2}
                    />
                    <br />
                    <button type="submit" className="p-5 bg-orange-500 text-white">
                        Generate
                    </button>
                </form>
                <div className="w-1/2">
                    {submit2 && value2 ? (
                        <div className="w-full">
                            <div>
                                <InstanceCharts
                                    title={'comment Count Chart Video'}
                                    name={'Video Comment Count'}
                                    path={`${process.env.REACT_APP_BASE_URL}/getTotalCommentsCountUsingVideo_id/${value2}`}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="text-lg font-semibold">Enter Video_id</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Allreports;
