// assets
import { IconDashboard } from '@tabler/icons';
import HistoryIcon from '@mui/icons-material/History';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import MusicVideoOutlinedIcon from '@mui/icons-material/MusicVideoOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import CameraFrontOutlinedIcon from '@mui/icons-material/CameraFrontOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ReportIcon from '@mui/icons-material/Report';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import EditIcon from '@mui/icons-material/Edit';

// constant
const icons = {
    PeopleOutlineOutlinedIcon,
    HistoryIcon,
    PaidOutlinedIcon,
    ConfirmationNumberOutlinedIcon,
    HowToRegOutlinedIcon,
    VideoLibraryOutlinedIcon,
    MusicVideoOutlinedIcon,
    OndemandVideoOutlinedIcon,
    FeaturedVideoOutlinedIcon,
    PermMediaOutlinedIcon,
    TrendingUpOutlinedIcon,
    PlayLessonOutlinedIcon,
    BookmarkAddOutlinedIcon,
    TagOutlinedIcon,
    TravelExploreOutlinedIcon,
    AdminPanelSettingsOutlinedIcon,
    SupervisedUserCircleOutlinedIcon,
    CurrencyRupeeOutlinedIcon,
    AccountBalanceWalletOutlinedIcon,
    DiamondOutlinedIcon,
    ManageAccountsOutlinedIcon,
    AddCommentOutlinedIcon,
    NotificationsActiveOutlinedIcon,
    PersonAddAltOutlinedIcon,
    ContentPasteSearchOutlinedIcon,
    RateReviewOutlinedIcon,
    CameraFrontOutlinedIcon,
    BrokenImageOutlinedIcon,
    EmojiEventsOutlinedIcon,
    AccessAlarmOutlinedIcon,
    ReportGmailerrorredOutlinedIcon,
    LockClockOutlinedIcon,
    IconDashboard,
    PeopleOutlineIcon,
    FollowTheSignsIcon,
    PaymentsIcon,
    SettingsIcon,
    CloudUploadOutlinedIcon,
    ImageSearchOutlinedIcon,
    UploadFileOutlinedIcon,
    ReportIcon,
    SmartToyIcon,
    EditIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.PeopleOutlineOutlinedIcon,
            children: [
                {
                    id: 'all-users',
                    title: 'All Users',
                    type: 'item',
                    url: '/all-users',
                    icon: icons.PeopleOutlineOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'all-verified-users',
                    title: 'All Verified Users',
                    type: 'item',
                    url: '/all-verified-users',
                    icon: icons.HowToRegOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'all-agency-users',
                    title: 'Agency Users',
                    type: 'item',
                    url: '/all-agency-users',
                    icon: icons.PeopleOutlineOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'profile-verification',
                    title: 'Profile Verification',
                    type: 'item',
                    url: '/profile-verification',
                    icon: icons.HowToRegOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'users-kyc',
                    title: "User's KYC",
                    type: 'item',
                    url: '/users-kyc',
                    icon: icons.AdminPanelSettingsOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'referral-user',
                    title: 'Referral User',
                    type: 'item',
                    url: '/referral-user',
                    icon: icons.PersonAddAltOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'user-wallet',
                    title: 'User Wallet',
                    type: 'item',
                    url: '/user-wallet',
                    icon: icons.AccountBalanceWalletOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'user-diamond',
                    title: 'User Diamond',
                    type: 'item',
                    url: '/user-diamond',
                    icon: icons.DiamondOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'marked-user',
                    title: 'Marked User',
                    type: 'item',
                    url: '/marked-user',
                    icon: icons.BookmarkAddOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'add-followers',
                    title: 'Add Followers',
                    type: 'item',
                    url: '/add-followers',
                    icon: icons.FollowTheSignsIcon,
                    breadcrumbs: false
                },
                {
                    id: 'bot-users',
                    title: 'Bot Users',
                    type: 'item',
                    url: '/bot-users',
                    icon: icons.EditIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'shots',
            title: 'Shots',
            type: 'collapse',
            icon: icons.OndemandVideoOutlinedIcon,
            children: [
                {
                    id: 'all-shots',
                    title: 'All Shots',
                    type: 'item',
                    url: '/all-shots',
                    icon: icons.OndemandVideoOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'trending-shots',
                    title: 'Trending Shots',
                    type: 'item',
                    url: '/trending-shots',
                    icon: icons.TrendingUpOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'trending-mark-shots',
                    title: 'Trending Mark Shots',
                    type: 'item',
                    url: '/trending-mark-shots',
                    icon: icons.TrendingUpOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'marked-shots',
                    title: 'Marked Shots',
                    type: 'item',
                    url: '/marked-shots',
                    icon: icons.PlayLessonOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'explore-short',
                    title: 'Explore Shots',
                    type: 'item',
                    url: '/explore-short',
                    icon: icons.TravelExploreOutlinedIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'clips',
            title: 'Clips',
            type: 'collapse',
            icon: icons.FeaturedVideoOutlinedIcon,
            children: [
                {
                    id: 'all-clip',
                    title: 'All Clip',
                    type: 'item',
                    url: '/all-clip',
                    icon: icons.FeaturedVideoOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'trending-clip',
                    title: 'Trending Clip',
                    type: 'item',
                    url: '/trending-clip',
                    icon: icons.TrendingUpOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'clip-uploader-user',
                    title: 'Clip Uploader User',
                    type: 'item',
                    url: '/clip-uploader-user',
                    icon: icons.VideoLibraryOutlinedIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'all-images',
            title: 'All Images',
            type: 'item',
            url: '/all-images',
            icon: icons.PermMediaOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'subscribers',
            title: 'Subscribers',
            type: 'collapse',
            icon: icons.PeopleOutlineIcon,
            children: [
                {
                    id: 'all-subscribers',
                    title: 'All Subscribers',
                    type: 'item',
                    url: '/all-subscribers',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: false
                },
                {
                    id: 'add-upi-subscriber',
                    title: 'Add UPI Subscriber',
                    type: 'item',
                    url: '/add-upi-subscriber',
                    icon: icons.PaymentsIcon,
                    breadcrumbs: false
                },
                {
                    id: 'all-failed-subscribers',
                    title: 'All Failed Subscribers',
                    type: 'item',
                    url: '/all-failed-subscribers',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'masters',
            title: 'Masters',
            type: 'collapse',
            icon: icons.ContentPasteSearchOutlinedIcon,
            children: [
                {
                    id: 'all-sounds',
                    title: 'All Sounds',
                    type: 'item',
                    url: '/all-sounds',
                    icon: icons.MusicVideoOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'manage-agency',
                    title: 'Manage Agency',
                    type: 'item',
                    url: '/manage-agency',
                    icon: icons.ManageAccountsOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'contest',
                    title: 'Contest',
                    type: 'item',
                    url: '/contest',
                    icon: icons.CameraFrontOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'creator',
                    title: 'Creator',
                    type: 'item',
                    url: '/creator',
                    icon: icons.SupervisedUserCircleOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'daily-reports',
                    title: 'Daily Reports',
                    type: 'item',
                    url: '/daily-reports',
                    icon: icons.ReportIcon,
                    breadcrumbs: false
                },
                {
                    id: 'admin-users',
                    title: 'Admin_users',
                    type: 'item',
                    url: '/admin_users',
                    icon: icons.ReportIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'utility',
            title: 'Utility',
            type: 'collapse',
            icon: icons.SettingsIcon,
            children: [
                {
                    id: 'user-feedback',
                    title: 'Feedback',
                    type: 'item',
                    url: '/user-feedback',
                    icon: icons.RateReviewOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'banner',
                    title: 'Manage Banner',
                    type: 'item',
                    url: '/banner',
                    icon: icons.BrokenImageOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'award-content',
                    title: 'Award Content',
                    type: 'item',
                    url: '/award-content',
                    icon: icons.EmojiEventsOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'time-event',
                    title: 'Time Event',
                    type: 'item',
                    url: '/time-event',
                    icon: icons.AccessAlarmOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'reported-items',
                    title: 'Reported Items',
                    type: 'item',
                    url: '/reported-items',
                    icon: icons.ReportGmailerrorredOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'reported-users',
                    title: 'Reported User',
                    type: 'item',
                    url: '/reported-users',
                    icon: icons.ReportGmailerrorredOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'withdraw-request',
                    title: 'Withdraw Request',
                    type: 'item',
                    url: '/withdraw-request',
                    icon: icons.CurrencyRupeeOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'add-comment',
                    title: 'Add Comment',
                    type: 'item',
                    url: '/add-comment',
                    icon: icons.AddCommentOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'add-notification',
                    title: 'Notification',
                    type: 'item',
                    url: '/add-notification',
                    icon: icons.NotificationsActiveOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'send-agency-notification',
                    title: 'Agency Notification',
                    type: 'item',
                    url: '/send-agency-notification',
                    icon: icons.NotificationsActiveOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'explore-hashtag',
                    title: 'Explore Hashtag',
                    type: 'item',
                    url: '/explore-hashtag',
                    icon: icons.TagOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'explore-short',
                    title: 'Explore Shots',
                    type: 'item',
                    url: '/explore-short',
                    icon: icons.TravelExploreOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'discovery-section',
                    title: 'Discovery Section',
                    type: 'item',
                    url: '/discovery-section',
                    icon: icons.ContentPasteSearchOutlinedIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'Upload',
            title: 'Upload section',
            type: 'collapse',
            icon: icons.CloudUploadOutlinedIcon,
            children: [
                {
                    id: 'form',
                    title: 'Upload Shots',
                    type: 'item',
                    url: '/form',
                    icon: icons.CloudUploadOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'images',
                    title: 'Upload Images',
                    type: 'item',
                    url: '/form-images',
                    icon: icons.ImageSearchOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'clips',
                    title: 'Upload CLips',
                    type: 'item',
                    url: '/form-clip',
                    icon: icons.UploadFileOutlinedIcon,
                    breadcrumbs: false
                }
                // {
                //     id: 'Upload Bot',
                //     title: 'Upload Bot',
                //     type: 'item',
                //     url: '/upload-bot',
                //     icon: icons.SmartToyIcon,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default pages;
