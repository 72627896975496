import PropTypes from 'prop-types';
import UsersContext from './UsersContext';
import { PostData, PostFormData } from '../../services';

const UsersState = (props) => {
    const adminUpdateUser = async (data) => {
        const res = await PostData(true, '/adminUpdateUser', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const addRewardAmount = async (data) => {
        const res = await PostData(true, '/addRewardAmount', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const allowUsertoUploadClip = async (data) => {
        const res = await PostData(true, '/allowUsertoUploadClip', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getUsers = async (data) => {
        const res = await PostData(true, '/All_Users', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getBotUsers = async (data) => {
        const res = await PostData(true, '/All_Bot_Users', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allVideos4User = async (data) => {
        const res = await PostData(true, '/admin_show_allVideos4User', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const All_VerifiedUsers = async (data) => {
        const res = await PostData(true, '/All_VerifiedUsers', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const All_Users_Agency = async (data) => {
        const res = await PostData(true, '/All_Users_Agency', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const All_Clip_Users = async (data) => {
        const res = await PostData(true, '/All_Clip_Users', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allProfileVerification = async (data) => {
        const res = await PostData(true, '/allProfileVerification', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateVerificationStatus = async (data) => {
        const res = await PostData(true, '/updateVerificationStatus', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allMarkedUser = async (data) => {
        const res = await PostData(true, '/admin_show_allMarkedUser', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allacceptdiamondsuser = async (data) => {
        const res = await PostData(true, '/admin_show_allacceptdiamondsuser', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getBank = async (data) => {
        const res = await PostData(true, '/getBank', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_creator = async (data) => {
        const res = await PostData(true, '/admin_show_creator', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getWithdrawRequest = async (data) => {
        const res = await PostData(true, '/getWithdrawRequest', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getWallet = async (data) => {
        const res = await PostData(true, '/getWallet', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const walletHistory = async (data) => {
        const res = await PostData(true, '/walletHistory', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getDiamond = async (data) => {
        const res = await PostData(true, '/getDiamond', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getadminAgency = async (data) => {
        const res = await PostData(true, '/getadminAgency', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };
    const createAgency = async (data, id) => {
        const res = await PostFormData(true, '/createAgency', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const adminAllReferral = async (data, id) => {
        const res = await PostData(true, '/adminAllReferral', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const all_list_discovery_sections = async (data, id) => {
        const res = await PostData(true, '/all_list_discovery_sections', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const add_discovery_Section = async (data, id) => {
        const res = await PostData(true, '/add_discovery_Section', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allFeedback = async (data, id) => {
        const res = await PostData(true, '/admin_show_allFeedback', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_show_allContest = async (data, id) => {
        const res = await PostData(true, '/admin_show_allContest', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getadminBanner = async (data, id) => {
        const res = await PostData(true, '/getadminBanner', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addbanner = async (data, id) => {
        const res = await PostFormData(true, '/addbanner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const editbanner = async (data, id) => {
        const res = await PostFormData(true, '/editbanner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllUsersByDate = async (data) => {
        const res = await PostData(true, '/getAllUsersByDate', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const All_Reported_user = async (data) => {
        const res = await PostData(true, '/All_Reported_user', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllState = async (data) => {
        const res = await PostData(true, '/getAllState', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getCitiesbyState = async (data) => {
        const res = await PostData(true, '/getCitiesbyState', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allsubscriber = async (data) => {
        const res = await PostData(true, '/allsubscriber', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const allfailedsubscriber = async (data) => {
        const res = await PostData(true, '/allfailedsubscriber', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const diamondHistory = async (data) => {
        const res = await PostData(true, '/diamondHistory', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addfollowers = async (data) => {
        const res = await PostData(true, '/addfollowers', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const addupisubscriber = async (data) => {
        const res = await PostData(true, '/addupisubscriber', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const varifiedWithdrawRequest = async (data) => {
        const res = await PostData(true, '/varifiedWithdrawRequest', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removefrommarked = async (data) => {
        const res = await PostData(true, '/removefrommarked', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removefromsubscribmarked = async (data) => {
        const res = await PostData(true, '/removefromsubscribmarked', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removeAgency = async (data) => {
        const res = await PostData(true, '/removeAgency', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const removebanner = async (data) => {
        const res = await PostData(true, '/removebanner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const orderBanner = async (data) => {
        const res = await PostData(true, '/orderBanner', data);
        if (res.status === 200) {
            return res.payload;
        }
    };
    const reportActionUser = async (data) => {
        const res = await PostData(true, '/reportActionUser', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const edit_profile = async (data) => {
        const res = await PostData(true, '/edit_profile', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <UsersContext.Provider
            value={{
                getUsers,
                getBotUsers,
                All_VerifiedUsers,
                All_Users_Agency,
                All_Clip_Users,
                allProfileVerification,
                adminUpdateUser,
                addRewardAmount,
                allowUsertoUploadClip,
                updateVerificationStatus,
                admin_show_allVideos4User,
                admin_show_allMarkedUser,
                admin_show_allacceptdiamondsuser,
                getBank,
                admin_show_creator,
                getWithdrawRequest,
                getWallet,
                walletHistory,
                getDiamond,
                getadminAgency,
                createAgency,
                adminAllReferral,
                all_list_discovery_sections,
                add_discovery_Section,
                admin_show_allFeedback,
                admin_show_allContest,
                getadminBanner,
                addbanner,
                getAllUsersByDate,
                All_Reported_user,
                getAllState,
                getCitiesbyState,
                allsubscriber,
                allfailedsubscriber,
                diamondHistory,
                addfollowers,
                addupisubscriber,
                varifiedWithdrawRequest,
                removefrommarked,
                removefromsubscribmarked,
                removeAgency,
                removebanner,
                orderBanner,
                editbanner,
                reportActionUser,
                edit_profile
            }}
        >
            {props.children}
        </UsersContext.Provider>
    );
};

UsersState.propTypes = {
    children: PropTypes.node
};

export default UsersState;
