import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// import './index.css';
import './index.css';

// routing
import Routes from 'routes';

import AuthState from './context/auth/AuthState';
import AllPathState from './context/all-path/AllPathState';

import Snack from './context/snack-bar/SnackState';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const ver = '1.0.0';

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Snack>
                        <AuthState>
                            <AllPathState>
                                <Routes />
                            </AllPathState>
                        </AuthState>
                    </Snack>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
