// // StockChart.js
// import React from 'react';
// import Chart from 'react-apexcharts';

// const StockChart = ({ title, name, dates }) => {
//     const options = {
//         series: [
//             {
//                 name: name,
//                 data: dates
//             }
//         ],
//         chart: {
//             type: 'area',
//             stacked: false,
//             height: 150,
//             zoom: {
//                 type: 'x',
//                 enabled: true,
//                 autoScaleYaxis: true
//             },
//             toolbar: {
//                 autoSelected: 'zoom'
//             }
//         },
//         dataLabels: {
//             enabled: false
//         },
//         markers: {
//             size: 0
//         },
//         title: {
//             text: title,
//             align: 'left'
//         },
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.5,
//                 opacityTo: 0,
//                 stops: [0, 100, 200, 300, 400, 500, 600]
//             }
//         },
//         yaxis: {
//             labels: {
//                 formatter: function (val) {
//                     return val.toFixed(0);
//                 }
//             },
//             title: {
//                 text: 'Count'
//             }
//         },
//         xaxis: {
//             type: 'datetime'
//         },
//         tooltip: {
//             shared: false,
//             y: {
//                 formatter: function (val) {
//                     return val.toFixed(0);
//                 }
//             }
//         },
//         colors: ['#ff9035']
//     };

//     return (
//         <div id="chart">
//             <Chart options={options} series={options.series} type="area" height={350} />
//         </div>
//     );
// };

// export default StockChart;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const StockChart = ({ title, name, dates }) => {
    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        const processDates = () => {
            if (dates.length === 0) return [];

            const parsedDates = dates.map((date) => ({
                x: dayjs(date.x),
                y: date.y
            }));

            const startDate = parsedDates[0].x;
            const endDate = parsedDates[parsedDates.length - 1].x;

            const allDates = [];
            let currentDate = startDate;

            while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
                allDates.push(currentDate);
                currentDate = currentDate.add(1, 'day');
            }

            const filledDates = allDates.map((date) => {
                const found = parsedDates.find((d) => d.x.isSame(date, 'day'));
                return found ? { x: date.toISOString(), y: found.y } : { x: date.toISOString(), y: 0 };
            });

            return filledDates;
        };

        setProcessedData(processDates());
    }, [dates]);

    const options = {
        series: [
            {
                name: name,
                data: processedData
            }
        ],
        chart: {
            type: 'area',
            stacked: false,
            height: 150,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0
        },
        title: {
            text: title,
            align: 'left'
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 100, 200, 300, 400, 500, 600]
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            title: {
                text: 'Count'
            }
        },
        xaxis: {
            type: 'datetime'
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            }
        },
        colors: ['#ff9035']
    };

    return (
        <div id="chart">
            <Chart options={options} series={options.series} type="area" height={350} />
        </div>
    );
};

export default StockChart;
