// import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SoundContext from './SoundContext';
import { PostData, PostFormData } from '../../services';

const SoundState = (props) => {
    const getSounds = async (data) => {
        const res = await PostData(true, '/admin_all_sounds', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_getSoundSection = async (data) => {
        const res = await PostData(true, '/admin_getSoundSection', { data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_addSection = async (data) => {
        const res = await PostData(true, '/admin_addSection', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const admin_editSection = async (data) => {
        const res = await PostData(true, '/admin_editSection', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removeSection = async (data) => {
        const res = await PostData(true, '/removeSection', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const removeSound = async (data) => {
        const res = await PostData(true, '/removeSound', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getAllSoundGallery = async (data) => {
        const res = await PostData(true, '/getAllSoundGallery', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const uploadSound = async (data, id) => {
        const res = await PostFormData(true, '/uploadSound', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <SoundContext.Provider
            value={{
                getSounds,
                admin_getSoundSection,
                admin_addSection,
                admin_editSection,
                removeSection,
                removeSound,
                getAllSoundGallery,
                uploadSound
            }}
        >
            {props.children}
        </SoundContext.Provider>
    );
};

SoundState.propTypes = {
    children: PropTypes.node
};

export default SoundState;
